//	Arrows
@mixin mm_arrow
{
	content: '';
	border: 3px solid transparent;
	display: inline-block;
	width: 10px;
	height: 10px;
	
	@include mm_webkit-prefix( "transform", rotate( -45deg ) );
}
@mixin mm_arrow-prev
{
	border-right: none;
	border-bottom: none;
}
@mixin mm_arrow-next
{
	border-top: none;
	border-left: none;
}


//	Borders
@mixin mm_border( $border, $pseudo, $size, $pos1, $pos2 )
{
	&:#{$pseudo}
	{
		content: '';
		border-#{$border}-width: 1px;
		border-#{$border}-style: solid;
		display: block;
		#{$size}: 100%;
		position: absolute;
		#{$pos1}: 0;
		#{$pos2}: 0;
	}
}
@mixin mm_border-top
{
	@include mm_border( "top", "before", "width", "top", "left" );
}
@mixin mm_border-right
{
	@include mm_border( "right", "after", "height", "right", "top" );
}
@mixin mm_border-bottom
{
	@include mm_border( "bottom", "after", "width", "bottom", "left" );
}
@mixin mm_border-left
{
	@include mm_border( "left", "before", "height", "left", "top" );
}


//	Misc
@mixin mm_vendor-prefix( $prop, $val )
{
	-webkit-#{$prop}: $val;
	-moz-#{$prop}: $val;
	-ms-#{$prop}: $val;
	-o-#{$prop}: $val;
	#{$prop}: $val;
}
@mixin mm_webkit-prefix( $prop, $val )
{
//	we're not yet ready to drop vendor prefixes due to IE9 and older versions of FF
	@include mm_vendor-prefix( $prop, $val );
//	-webkit-#{$prop}: $val;
//	#{$prop}: $val;
}
@mixin mm_ellipsis()
{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
@mixin mm_clearfix()
{
	&:after
	{
		content: '';
		display: block;
		clear: both;
	}
}