@mixin mm_colors( $cls: "",
	$baseBg: $mm_backgroundColor, $pageShadow: $mm_pageShadow,
	$color: $mm_textColor, $dimmedColor: $mm_dimmedTextColor,
	$emphasizedBg: $mm_emphasizedBackgroundColor, $highlightedBg: $mm_highlightedBackgroundColor,
	$borderColor: $mm_borderColor
) {
	html.mm-opened#{$cls} .mm-page
	{
		box-shadow: $pageShadow;
	}
	.mm-menu#{$cls}
	{
		background: $baseBg;
		color: $color;

		.mm-list
		{
			> li:after
			{
				border-color: $borderColor;
			}
			> li
			{
				> a
				{
					&.mm-subclose
					{
						background: $emphasizedBg;
						color: $dimmedColor;
					}
					&.mm-subopen:after,
					&.mm-subclose:before
					{
						border-color: $dimmedColor;
					}
					&.mm-subopen:before
					{
						border-color: $borderColor;
					}
				}
			}
			> li.mm-selected
			{
				> a:not(.mm-subopen),
				> span
				{
					background: $emphasizedBg;
				}
			}
			> li.mm-label 
			{
				background: $highlightedBg;
			}
		}
		
		&.mm-vertical .mm-list
		{
			li.mm-opened
			{
				> a.mm-subopen,
				> ul
				{
					background: $highlightedBg;
				}
			}
		}
	}
}

@mixin mm_colors_buttonbars( $cls: "",
	$baseBg: $mm_backgroundColor,
	$color: $mm_textColor
) {
	.mm-menu#{$cls}
	{
		.mm-buttonbar
		{
			border-color: $color;
			background: $baseBg;
			
			> *
			{
				border-color: $color;
			}
			
			> input:checked + label
			{
				background: $color;
				color: $baseBg;
			}
		}
	}
}

@mixin mm_colors_checks( $cls: "",
	$color: $mm_textColor
) {
	.mm-menu#{$cls} label.mm-check:before
	{
		border-color: $color;
	}
}

@mixin mm_colors_counters( $cls: "",
	$dimmedColor: $mm_dimmedTextColor
) {
	.mm-menu#{$cls} em.mm-counter
	{
		color: $dimmedColor;
	}
}

@mixin mm_colors_footer( $cls: "",
	$dimmedColor: $mm_dimmedTextColor,
	$borderColor: $mm_borderColor
) {
	.mm-menu#{$cls}
	{
		.mm-footer
		{
			border-color: $borderColor;
			color: $dimmedColor;
		}
	}
}

@mixin mm_colors_header( $cls: "",
	$dimmedColor: $mm_dimmedTextColor,
	$borderColor: $mm_borderColor
) {
	.mm-menu#{$cls}
	{
		.mm-header
		{
			border-color: $borderColor;
			color: $dimmedColor;

			.mm-prev:before,
			.mm-next:after,
			.mm-close:after
			{
				border-color: $dimmedColor;
			}
		}
	}
}

@mixin mm_colors_labels( $cls: "",
	$highlightedBg: $mm_highlightedBackgroundColor
) {
	.mm-menu#{$cls}
	{
		.mm-list li.mm-label > div > div
		{
			background: $highlightedBg;
		}
	}
}

@mixin mm_colors_searchfield( $cls: "",
	$inputBg: $mm_dimmedTextColor, $color: $mm_textColor,
	$dimmedColor: $mm_dimmedTextColor
) {
	.mm-menu#{$cls}
	{
		.mm-search input
		{
			background: $inputBg;
			color: $color;
		}
		.mm-noresultsmsg
		{
			color: $dimmedColor;
		}
	}
}

@mixin mm_colors_toggles( $cls: "",
	$buttonBg: $mm_backgroundColor,
	$offBg: $mm_borderColor, $onBg: $mm_toggleCheckedColor
) {
	.mm-menu#{$cls}
	{
		label.mm-toggle
		{
			background: $offBg;
		
			&:before
			{
				background: $buttonBg;
			}
		}
		input.mm-toggle:checked ~ label.mm-toggle
		{
			background: $onBg;
		}
	}
}