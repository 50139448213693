//	Animations
$mm_transitionDuration: 0.4s !default;
$mm_transitionFunction: ease !default;

//	Sizes
$mm_width		: 0.8 !default;
$mm_minWidth	: 140px !default;
$mm_maxWidth	: 440px !default;

$mm_height		: 0.8 !default;
$mm_minHeight	: 140px !default;
$mm_maxHeight	: 880px !default;

$mm_btnSize		: 40px !default;
$mm_padding		: 10px !default;
$mm_fontSize	: 14px !default;

$mm_subpanelOffset	: 30% !default;
$mm_subopenWidth	: $mm_btnSize !default;

//	Addon sizes
$mm_buttonbarHeight		: $mm_btnSize - ( $mm_padding * 2 ) !default;

$mm_counterWidth		: $mm_btnSize !default;

$mm_toggleHeight		: $mm_btnSize - $mm_padding !default;
$mm_toggleWidth			: ( $mm_toggleHeight * 2 ) - $mm_padding !default;

$mm_checkHeight			: $mm_btnSize - $mm_padding !default;
$mm_checkWidth			: $mm_btnSize - $mm_padding !default;

$mm_footerHeight		: $mm_btnSize;

$mm_headerHeight		: $mm_btnSize * 1.5 !default;
$mm_headerPaddingTop	: $mm_headerHeight / 2 !default;

$mm_labelHeight			: ( $mm_btnSize / 2 ) + ( $mm_padding / 2 ) !default;

$mm_searchHeight		: $mm_btnSize + $mm_padding !default;
$mm_searchfieldHeight	: $mm_searchHeight - ( $mm_padding * 2 ) !default;

//	Colors
$mm_pageShadow					: 0 0 20px rgba( 0, 0, 0, 0.5 ) !default;
$mm_borderColor					: rgba( 0, 0, 0, 0.15 ) !default;
$mm_backgroundColor				: #333 !default;
$mm_emphasizedBackgroundColor	: rgba( 0, 0, 0, 0.1 ) !default;
$mm_highlightedBackgroundColor	: rgba( 255, 255, 255, 0.05 ) !default;
$mm_textColor					: rgba( 255, 255, 255, 0.6 ) !default;
$mm_dimmedTextColor				: rgba( 255, 255, 255, 0.3 ) !default;

//	Addon colors
$mm_toggleCheckedColor	: #4bd963 !default;

@import "mixins";
@import "sizing";
@import "colors";