/**
 * Home
 */

// Add a color screen over the slider images
.slider__home li {
  background: $brand-color-0;
}

.slider__home img {
  opacity: .95;
}

// home intro message
.homeintro__holder {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include breakpoint(bp-tablet-portrait) {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }

}

.homeintro__title {
  //@extend .h2;
  color: $brand-color-0;
  font-weight: normal;
  font-weight: $fw-normal;
}

.homeintro__message {
  color: lighten($brand-color-1-light, 20%);
}

// the 'down' chevron at the bottom of the slider
.home__hintholder {

  //don't show in mobile
  display: none;

  @include breakpoint(bp-tablet-portrait) {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    width: auto;
    background: transparent;
    margin-bottom: -50px !important;
    //filter: alpha(opacity=75);
    //opacity: 0.75;
  }

}

.home__hint {
  display: inline-block;
  padding: 1rem;
  background-color: rgba(143,165,149,.35);
  border: 2px solid #8fa595;
  @include border-radius(50%);
  color: rgba(244,199,65,.9);
  font-weight: normal;
  font-weight: $fw-normal;
}
