/**
 * Box sizing
 */


html {
  @include bbox;  
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
