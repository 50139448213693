/**
 * Page
 */

html {
  color: $base-text-color;
  font-size: $base-font-size;

    @include breakpoint(bp-tablet-landscape) {
      font-size: $base-font-size-lg;
    }

  font-family: $sans;
  line-height: $base-line-height;
  -webkit-font-smoothing: subpixel-antialiased !important;
}

html,
body { 
  height: 100%; 
}

body {
  margin: 0 auto; 
  background-color: $page-background;
  font-size: 100%;
}

a, 
a img, 
input, 
textarea,
button {
  @include trans;
}

// a mobile only line break
br.br__mobile {

  // 768px and up
  @include breakpoint(bp-tablet-portrait) {
    display: none;
  }

}

hr {
  margin-top: $hr-margin-top;
  margin-bottom: $hr-margin-bottom;
  border: $hr-border;
  border-bottom: $hr-border-bottom;
}

strong { 
  font-weight: bold; 
  font-weight: $fw-semibold;
}
