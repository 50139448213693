/**
 * z-index settings
 */

.z1 { z-index: 1 !important; }
.z2 { z-index: 2 !important; }
.z3 { z-index: 3 !important; }
.z4 { z-index: 4 !important; }
.z5 { z-index: 5 !important; }
.z6 { z-index: 6 !important; }
