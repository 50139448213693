/**
 * Placeholders
 */


/**
 * Clearfix
 * Example usage:
 * .wrap {
 * 		...
 * 		@extend %clearfix;
 * }
 */
%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
