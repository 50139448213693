/*
	jQuery.mmenu panels CSS
*/
@import "inc/variables";

//	Animations
.mm-menu.mm-horizontal > .mm-panel
{
	-webkit-transition: -webkit-transform $mm_transitionDuration $mm_transitionFunction;
	transition: transform $mm_transitionDuration $mm_transitionFunction;
}

//	Generic classes
.mm-menu .mm-hidden
{
	display: none;
}


//	Container
.mm-wrapper
{
	overflow-x: hidden;
	position: relative;
}

//	Menu
.mm-menu,
.mm-menu > .mm-panel
{
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
}
.mm-menu
{
	background: inherit;
	display: block;
	overflow: hidden;
	padding: 0;

	> .mm-panel
	{
		background: inherit;

		-webkit-overflow-scrolling: touch;
		overflow: scroll;
		overflow-x: hidden;
		overflow-y: auto;

		box-sizing: border-box;	
		padding: $mm_padding * 2;

		@include mm_webkit-prefix( 'transform', translate( 100%, 0 ) );
		
		&.mm-opened
		{
			@include mm_webkit-prefix( 'transform', translate( 0%, 0 ) );
		}
		&.mm-subopened
		{
			@include mm_webkit-prefix( 'transform', translate( -$mm_subpanelOffset, 0 ) );
		}
		&.mm-highest
		{
			z-index: 1;
		}
	}

	//	Lists
	.mm-list
	{
		padding: ( $mm_btnSize / 2 ) 0;
	}
	> .mm-list
	{
		padding-bottom: 0;

		&:after
		{
			content: '';
			display: block;
			height: $mm_btnSize;
		}
	}
}
.mm-panel > .mm-list
{
	margin-left: -( $mm_padding * 2 );
	margin-right: -( $mm_padding * 2 );
	
	&:first-child
	{
		padding-top: 0;
	}
}

.mm-list,
.mm-list > li
{
	list-style: none;
	display: block;
	padding: 0;
	margin: 0;
}
.mm-list
{
	font: inherit;
	font-size: $mm_fontSize;

	a,
	a:hover
	{
		text-decoration: none;
	}

	> li
	{
		position: relative;

		> a,
		> span
		{
			@include mm_ellipsis;

			color: inherit;
			line-height: $mm_btnSize - ( $mm_padding * 2 );
			display: block;
			padding: $mm_padding $mm_padding $mm_padding ( $mm_padding * 2 );
			margin: 0;
		}
	}
	
	> li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults)
	{
		@include mm_border-bottom;

		&:after
		{
			width: auto;
			margin-left: ( $mm_padding * 2 );
			position: relative;
			left: auto;
		}
	}

	// subopen/close
	a.mm-subopen
	{
		@include mm_border-left;

		background: rgba( 3, 2, 1, 0 );
		width: $mm_subopenWidth;
		height: 100%;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;

		&.mm-fullsubopen
		{
			width: 100%;
			
			&:before
			{
				border-left: none;
			}
		}

		+ a,
		+ span
		{
			padding-right: ( $mm_padding / 2 );
			margin-right: $mm_subopenWidth;
		}
	}

	> li.mm-selected
	{
		> a.mm-subopen
		{
			background: transparent;
		}
		> a.mm-fullsubopen
		{
			+ a,
			+ span
			{
				padding-right: $mm_btnSize + ( $mm_padding / 2 );
				margin-right: 0;
			}
		}
	}
	
	a.mm-subclose
	{
		text-indent: $mm_btnSize - ( $mm_padding * 2 );
		padding-top: ( $mm_btnSize / 2 ) + $mm_padding;
		margin-top: -( $mm_btnSize / 2 );
	}

	// Labels
	> li.mm-label
	{
		@include mm_ellipsis;

		font-size: 10px;
		text-transform: uppercase;
		text-indent: $mm_padding * 2;
		line-height: $mm_labelHeight;
		padding-right: $mm_padding / 2;
	}

	// Spacers
	> li.mm-spacer
	{
		padding-top: $mm_btnSize;
		
		&.mm-label
		{
			padding-top: $mm_labelHeight;
		}
	}

	// Arrows
	a.mm-subopen:after,
	a.mm-subclose:before
	{
		@include mm_arrow;

		margin-bottom: -5px;
		position: absolute;
		bottom: 50%;
	}
	a.mm-subopen:after
	{
		@include mm_arrow-next;

		right: 18px;
	}
	a.mm-subclose:before
	{
		@include mm_arrow-prev;

		margin-bottom: -( $mm_padding * 2 ) + 5;
		left: 22px;
	}
}

// vertical submenu
.mm-menu.mm-vertical .mm-list
{
	.mm-panel
	{
		display: none;
		padding: $mm_padding 0 $mm_padding $mm_padding;
		
		li:last-child:after
		{
			border-color: transparent;
		}
	}
	li.mm-opened > .mm-panel
	{
		display: block;
	}
	> li
	{
		> a.mm-subopen
		{
			height: $mm_btnSize;
			&:after
			{
				top: ( $mm_btnSize / 2 ) - 4;
				bottom: auto;
			}
		}
		&.mm-opened
		{
			> a.mm-subopen:after
			{
				@include mm_webkit-prefix( "transform", rotate( 45deg ) );
			}
		}
		&.mm-label > a.mm-subopen
		{
			height: $mm_labelHeight;
		}
	}
}

@include mm_colors;