/**
 * Mobile-First 12-column Grid
 */

.grid {
  width: 100%; 
  &:after {  
    content: "";
    display: table;
    clear: both;
  }
}

// Mobile-first single column grid
[class*='col-'] {
  float: none; 
  width: 100%; 
  margin-right: 0;
}

// Until we're above this breakpoint
@include breakpoint(bp-tablet-portrait) {

  // Generate all the column widths
  @for $column from 1 through $grid-columns {
    .col-#{$column} {
      width: (($single-col-width * $column) + (($column - 1) * $grid-gutter) * 1%)
    }
  }

  // Add multi-column settings
  [class*='col-'] {
    float: left; 
    margin-right: $grid-gutter * 1%; 
    .grid &:last-of-type {
      margin-right: 0; 
    }
    border-collapse: collapse !important;
  }

}

// a single column option
.single-col {
  margin: 0 auto;
  width: 100%;
  max-width: 760px;
}

/* @for $column... generates this:
.col-1  { width: 5.5833333333%; }
.col-2  { width: 14.1666666667%; }
.col-3  { width: 22.75%; }
.col-4  { width: 31.3333333333%; }
.col-5  { width: 39.9166666667%; }
.col-6  { width: 48.5%; }
.col-7  { width: 57.0833333333%; }
.col-8  { width: 65.6666666667%; }
.col-9  { width: 74.25%; }
.col-10 { width: 82.8333333333%; }
.col-11 { width: 91.4166666667%; }
.col-12 { width: 100%; }
*/
