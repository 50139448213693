/**
 * Panels
 */

.panel {
  margin: 0 auto 1rem auto;
  width: 100%;
  background-color: $panel-background-color;
  padding: $panel-padding;
  max-width: $panel-max-width;
  border: $panel-border;
  @include border-radius($panel-border-radius);
}

.panel__title {
  color: $panel-title-color;
  margin-top: 0 !important;
}

.panel__copy {
  color: $panel-copy-color;
}

// panel for forms
.panel--form {
  @extend .panel;
  border: $panel-form-border;
  background-color: $panel-form-background-color;
  padding: $panel-form-padding;
}
