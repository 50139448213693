/**
 *
 * Custom
 *
 * This partial is specifically for custom styles that apply to ALL pages.
 * Place partials for each individual page in pages/pagename.scss
 *
 */

/* Colors, for reference (from _variables)
$brand-color-0: #31473c;
$brand-color-1: #537363;
$brand-color-2: #703606;
$site-color-3: #333;
$site-color-6: #666;
$site-color-9: #999;
$site-color-c: #ccc;
$site-color-f: #f6f6f6;
*/

// ----------- Color classes

.bcolor__0 { color: $brand-color-0; }
.bcolor__1 { color: $brand-color-1; }
.bcolor__2 { color: $brand-color-2; }
.scolor__3 { color: $site-color-3; }
.scolor__6 { color: $site-color-6; }
.scolor__9 { color: $site-color-9; }
.scolor__c { color: $site-color-c; }
.scolor__f { color: $site-color-f; }

// ----------- Background color classes

.bg__0 { background-color: $brand-color-0; }
.bg__1 { background-color: $brand-color-1; }
.bg__2 { background-color: $brand-color-2; }
.bg__3 { background-color: $site-color-3; }
.bg__6 { background-color: $site-color-6; }
.bg__9 { background-color: $site-color-9; }
.bg__c { background-color: $site-color-c; }
.bg__d { background-color: $site-color-d; }
.bg__f { background-color: $site-color-f; }
.bg__e { background-color: $site-color-e; }
// ----------- Sub-page Titles

.sub__title {
  color: $brand-color-0;
  margin: 0;
  padding: $spacing 0;
}

// ----------- Sub-page Breadcrumbs

.breadcrumbs__holder {
  border-bottom: 1px solid $site-color-c;
}

.breadcrumbs__wrap {
  padding: 1rem 0;
}

.breadcrumbs__links {
  font-size: .75rem;
  line-height: 1em;
}

.breadcrumbs__links li {
  display: inline-block;
  text-transform: uppercase;

  &:after {
    content: ' / ';
  }
  &:last-child:after {
    content: '';
  }

}

.breadcrumbs__links a {
  text-decoration: none;
}

// ----------- Sub-page Masthead Imgs

.header-img {
  margin-bottom: 0; 
  max-height: 250px; 
  overflow-y: hidden;

 @include below(400px) {
    max-height: 100px;
  } 
}

// ----------- H3 links

h3 a {
  text-decoration: none;
}

// ----------- Ordered lists

ol {
  padding-left: 2rem;
}

// ----------- Sup Sub

sup {
    top: -0.15em;
}
sub, sup {
    font-size: 65%;
}