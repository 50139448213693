/**
 * https://github.com/ccampbell/rainbow/tree/master/themes
 *
 * Solarized Light theme
 *
 * Adaptation of Solarized Light from ethanschoonover.com/solarized
 *
 * @author Ethan Schoonover
 * @author David Litmark
 * @version 1.0.0
 */

$mono-font: "Consolas", Monaco, Menlo, "Courier New", monospace;

.pre-holder {
    /*
    overflow-x: scroll !important;
    white-space: nowrap !important;
    normal
    pre
    nowrap
    prewrap
    preline
    */
}

pre {
    background: #fdf6e3; /* base3 */
    word-wrap: break-word;
    margin: 0px;
    //padding: 10px;
    padding: 0;
    color: #657b83; /* base00 */
    font-size: .75rem;
    //margin-bottom: 20px;
    margin-bottom: 0;
    overflow: auto;
}

pre span {
    margin: 0;
    padding: 0;
    line-height: 1em;
}

pre, code {
    //font-family: 'Monaco', courier, monospace;
    font-family: $mono-font;
}

pre .comment {
    color: #93a1a1; /* base1 */
}

pre .constant {
    color: #657b83; /* base00 */
}

pre .constant.language {
    color: #268bd2; /* blue */
}

pre .constant.regexp {
    color: #2aa198; /* cyan */
}

pre .storage {
    color: #268bd2; /* blue */
}

pre .string, pre .comment.docstring {
    color: #2aa198; /* cyan */
}

pre .support.tag.script, pre .support.tag.style {
    color: #2aa198; /* cyan */
}

pre .string.regexp {
    color: #2aa198; /* cyan */
}

pre .string.regexp.open, pre .string.regexp.close {
    color: #2aa198; /* cyan */
}

pre .keyword, pre .selector {
    color: #859900; /* green */
}

pre .inherited-class {
    font-style: italic;
}

pre .entity {
    color: #b58900; /* yellow */
}

pre .support, *[data-language="c"] .function.call {
    color: #859900; /* green */
}

pre .support.method {
    color: #657b83; /* base00 */
}

pre .support.property {
    color: #657b83; /* base00 */
}

pre .variable.global, pre .variable.class, pre .variable.instance {
    color: #657b83; /* base00 */
}
