/*
	jQuery.mmenu offcanvas addon CSS
*/

@import "../inc/variables";


//	Animations
.mm-page
{
	box-sizing: border-box;
	position: relative;
}
.mm-slideout
{
	-webkit-transition: -webkit-transform $mm_transitionDuration $mm_transitionFunction;
	-ms-transition: -ms-transform $mm_transitionDuration $mm_transitionFunction;
	transition: transform $mm_transitionDuration $mm_transitionFunction;
}

//	Container, Page, Blocker
html.mm-opened
{
	overflow: hidden;
	position: relative;
	
	body
	{
		overflow: hidden;
	}
}

html.mm-background .mm-page
{
	background: inherit;
}
#mm-blocker
{
	background: rgba( 3, 2, 1, 0 );
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
}
html.mm-opened,
html.mm-blocking
{
	#mm-blocker
	{
		display: block;
	}
}

//	Menu
.mm-menu
{
	&.mm-offcanvas
	{
		display: none;
		position: fixed;
	}
	&.mm-current
	{
		display: block;
	}
}


@include mm_sizing;