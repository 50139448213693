/**
 * Header
 */

[role=banner] {
  //text-align: center;
}

.header, 
.header * { 
  @extend .z6; 
}

nav {
  font-family: $nav-global-font;
  font-weight: $nav-global-font-weight;
  font-size: $nav-global-font-size;
  line-height: $nav-global-line-height;
  display: block;
}
  
// ----------- Mobile-first navigation display

.container--navutility {

  @extend .container;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  // hide it in mobile-first display
  display: none;

  background-color: $navutility-holder-bg; 
  border-top: 5px solid $navutility-holder-bg;
  border-bottom: 5px solid $navutility-holder-bg;

    // and show it above bp-tablet-portrait size
    @include above(900px) {
      display: block;
    }

}

// separate column break for the main nav
.navmain__col {

  @include below(900px) {
    display: block;
    width: 100%;
  }

}

.navmain__nav {
  display: none;

  @include above(900px) {
    display: inline-block;
  }

}

.mmenu-trigger__holder { 
  display: block;
  text-align: $mmenu-trigger-align;
  background-color: $brand-color-0;

  &:before,
  &:after {
    content: " ";
    display: table
  }
  &:after {
    clear: both;
  }
  
  @include above(900px) {
    display: none;
  }

}

.mmenu-trigger { 
  font-family: $sans;
  font-size: 1.5rem;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1.25rem;
  display: block;
  color: white;

  &:visted {
    color: white;
  }
  &:hover,
  &:active,
  &:focus {
    color: white;
  }

}

.mmenu-trigger span {
  font-weight: normal;
  font-weight: $fw-normal;
}

.mmenu-trigger span,
.mmenu-trigger .fa {
  display: inline-block;
  vertical-align: middle;
}

.mmenu-trigger .fa { 
  padding-left: .5rem; 
  font-weight: normal;
  font-weight: $fw-normal;
}

// show the desktop logo only 900px and above
.logo__desktop {
  display: none;

  @include above(900px) {
    display: block !important;
  }

}

// show the desktop logo only 900px and below
.logo__mobile {
  display: block;
  text-align: center;

  > img {
    margin: 0 auto;
  }

  @include above(900px) {
    display: none !important;
  }

}

// ----------- /Mobile-first navigation display
